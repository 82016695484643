import React from "react";
import Layout from "../comps/reusable/Layout";
import { Link, graphql } from "gatsby";
import Seo from "../comps/reusable/Seo";
import {
  AboutSection,
  CarbonNeutralSecction,
  ContactSection,
  HeroSection,
  InfoSection,
} from "../styles/pages/carbon-neutrality";
import carlosPol from "../images/carbon/carlos-pol.png";
import climateNeutral from "../images/logos/CARBON_NEUTRAL_BLACK.png";
import climeworks from "../images/carbon/climeworks.png";
import cooleffect from "../images/carbon/cooleffect.png";
import lapiezaEco from "../images/carbon/lapieza-eco.png";
import { useTranslation } from "react-i18next";

const Pledge = () => {
  const { t } = useTranslation("carbon");

  return (
    <>
      <Seo
        title="Comprometidos con reducir la huella de carbono digital."
        description="En LaPieza, compartimos nuestra ambición para ayudar a otros a acelerar su progreso, comenzando por el impacto de la huella de carbono digital."
        image="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FSEO%2FTAG3.png?alt=media&token=1e0186ca-89d1-4e65-9386-e05e7e7c4ce7"
      />
      <Layout>
        <HeroSection>
          <div className="main-landing-container">
            <article>
              <h1>{t("heroTitle")}</h1>
              <p>{t("heroSubtitle")}</p>
            </article>
          </div>
        </HeroSection>
        <InfoSection>
          <div className="info-section main-landing-container-row">
            <article>
              <div className="info-section-title">
                <span>“</span>
                <h2>{t("infoTitle")}</h2>
              </div>
              <p className="info-section-description">{t("infoDescription")}</p>
            </article>
            <figure>
              <img src={carlosPol} alt="Co-fundadores de LaPieza" />
            </figure>
          </div>
        </InfoSection>
        <CarbonNeutralSecction>
          <div className="carbon-neutral-section main-landing-container-column">
            <div className="info-section">
              <figure>
                <img src={climateNeutral} alt="Carbon Neutral certified" />
              </figure>
              <article className="info-section-item">
                <h2>{t("carbonTitle")}</h2>
                <p>{t("carbonDescription")}</p>
                <Link to="/contact">{t("seeMore")}</Link>
              </article>
            </div>
            <article className="data-section">
              <div className="data-section-title">
                <span className="data-section-num">126</span>
                <span className="data-section-tco">
                  {" "}
                  tCO<span>2</span>
                </span>
                <h2>{t("carbonItemTitle")}</h2>
              </div>
              <p>{t("carbonItemDescription")}</p>
            </article>
          </div>
        </CarbonNeutralSecction>
        <AboutSection>
          <div className="main-landing-container-column">
            <h2>{t("aboutUsTitle")}</h2>
            <h3>{t("aboutUsSubtitle")}</h3>
            <div className="climeworks-section">
              <article>
                <h5>{t("aboutUsItemTitle1")}</h5>
                <p>{t("aboutUsItemSubtitle1")}</p>
                <a href="">
                  {t("seeMore")} {">"}
                </a>
              </article>
              <figure>
                <img src={climeworks} alt="Climeworks" />
              </figure>
            </div>
            <div className="cooleffect-section">
              <figure>
                <img src={cooleffect} alt="CoolEffect" />
              </figure>
              <article>
                <h5>{t("aboutUsItemTitle2")}</h5>
                <p>{t("aboutUsItemSubtitle2")}</p>
                <p>{t("aboutUsItemDescription2")}</p>
                <ul>
                  <li>{t("aboutUsListItem1")}</li>
                  <li>{t("aboutUsListItem2")}</li>
                  <li>{t("aboutUsListItem3")}</li>
                </ul>
                <a href="">
                  {t("seeMore")} {">"}
                </a>
              </article>
            </div>
          </div>
        </AboutSection>
        <ContactSection>
          <div className="contact-section main-landing-container-row">
            <article>
              <h5>{t("contactTitle")}</h5>
              <p>{t("contactDescription")}</p>
              <p>
                {t("contactMessage")}{" "}
                <a href="mailto:carlos@lapieza.io">carlos@lapieza.io</a>
              </p>
              <p>{t("contactMessage2")}</p>
              <Link className="contact-button" to="/contact">{t("contactTeam")}</Link>
            </article>
            <figure>
              <img src={lapiezaEco} alt="Neutralidad de carbono | LaPieza" />
            </figure>
          </div>
        </ContactSection>
      </Layout>
    </>
  );
};

export default Pledge;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
