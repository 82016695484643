import styled from "styled-components";
import heronc from "../../images/carbon/hero-nc.png";
import { fonts, landingPalette } from "../styles";

export const HeroSection = styled.section`
  background-image: url("${heronc}");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 47rem;
  position: relative;
  padding: 0 80px 0 80px;
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    padding: 0 32px 0 32px;
  }

  @media (max-width: 425px) {
    padding: 0 16px 0 16px;
  }

  .main-landing-container {
    max-width: 1280px;
    width: 100%;
    margin: auto auto 9rem auto;
  }

  ::after {
    content: " ";
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    opacity: 0.15;
    z-index: 1;
  }

  article {
    position: relative;
    z-index: 2;
  }

  h1 {
    font-size: 24px;
    line-height: 29px;
    color: white;
    font-family: ${fonts.SofiaProMedium};
    margin-bottom: 16px;

    @media (max-width: 375px) {
      font-size: 20px;
    }
  }

  p {
    font-size: 5.2rem;
    line-height: 6.4rem;
    color: white;
    font-family: ${fonts.SofiaProBold};
    max-width: 59rem;
    margin-bottom: 16px;

    @media (max-width: 425px) {
      font-size: 3.6rem;
    }

    @media (max-width: 375px) {
      font-size: 28px;
    }
  }
`;

export const InfoSection = styled.section`
  min-height: 51.6rem;
  background-color: ${landingPalette.bgBlue};

  .info-section {
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media (max-width: 1190px) {
      flex-wrap: wrap;
    }
  }

  figure {
    max-width: 56rem;
    width: 100%;

    @media (max-width: 1190px) {
      max-width: 100%;

      img {
        object-fit: cover;
        width: 100%;
        max-height: 50rem;
        object-position: top;
      }
    }
  }

  article {
    padding: 4.5rem 80px 4.5rem 40px;
    width: 100%;

    @media (max-width: 1190px) {
      padding: 4.5rem 80px 4.5rem 80px;
    }

    @media (max-width: 600px) {
      padding: 4.5rem 32px 4.5rem 32px;
    }

    @media (max-width: 425px) {
      padding: 4.5rem 16px 4.5rem 16px;
    }

    span {
      font-size: 10rem;
      font-family: ${fonts.SofiaProBold};
      color: ${landingPalette.blue};

      @media (max-width: 768px) {
        font-size: 5rem;
      }

      @media (max-width: 600px) {
        font-size: 3rem;
      }
    }

    .info-section-title {
      display: flex;
      grid-gap: 8px;
      margin-bottom: 3.2rem;
      margin-left: -5rem;

      @media (max-width: 600px) {
        margin-left: -2em;
        grid-gap: 4px;
      }

      span {
        line-height: 1;
      }

      h2 {
        font-size: 2.8rem;
        line-height: 4.2rem;
        color: ${landingPalette.blue};
        font-family: ${fonts.SofiaProSemiBold};
        max-width: 52rem;

        @media (max-width: 1190px) {
          max-width: 100%;
        }
      }
    }

    .info-section-description {
      font-size: 1.8rem;
      line-height: 2.8rem;
      color: ${landingPalette.greyChevron};
      font-family: ${fonts.sofiaProRegular};
      max-width: 50.9rem;

      @media (max-width: 1190px) {
        max-width: 100%;
      }
    }
  }
`;

export const CarbonNeutralSecction = styled.section`
  padding: 8.8rem 80px;

  @media (max-width: 600px) {
    padding: 8.8rem 32px;
  }

  @media (max-width: 425px) {
    padding: 8.8rem 16px;
  }

  .carbon-neutral-section {
    width: 100%;
  }

  .info-section {
    display: flex;
    grid-gap: 9rem;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8.7rem;
    width: 100%;

    @media (max-width: 920px) {
      flex-direction: column-reverse;
    }

    figure {
      max-width: 360px;
      width: 100%;

      img {
        width: 100%;
        object-fit: scale-down;
      }
    }

    .info-section-item {
      max-width: 54rem;
      width: 100%;

      h2 {
        font-size: 3.8rem;
        line-height: 4.8rem;
        color: ${landingPalette.blue};
        font-family: ${fonts.SofiaProSemiBold};
        margin-bottom: 2rem;
        max-width: 44rem;
      }

      p {
        font-size: 1.6rem;
        line-height: 2.8rem;
        color: ${landingPalette.greyChevron};
        font-family: ${fonts.SofiaProSemiBold};
        margin-bottom: 4rem;
      }

      button,
      a {
        border: none;
        outline: none;
        background-color: ${landingPalette.orange};
        border-radius: 21px;
        height: 42px;
        padding: 0 60px;
        font-family: ${fonts.SofiaProMedium};
        font-size: 1.6rem;
        color: white;
        line-height: 2rem;
        text-decoration: none;
        display: inline-flex;
        align-items: center;
      }
    }
  }

  .data-section {
    width: 100%;
    background-color: ${landingPalette.blueFour};
    border-radius: 10px;
    padding: 6.6rem 5.5rem 5rem 5.5rem;

    @media (max-width: 600px) {
      padding: 6.6rem 2rem 5rem 2rem;
    }

    .data-section-title {
      @media (max-width: 920px) {
        text-align: center;
      }

      .data-section-num {
        font-size: 6.6rem;
        color: white;
        font-family: ${fonts.SofiaProSemiBold};
      }

      .data-section-tco {
        font-size: 3.4rem;
        line-height: 2rem;
        color: white;
        font-family: ${fonts.SofiaProSemiBold};
        position: relative;

        span {
          position: absolute;
          font-size: 1.7rem;
          top: -0.5rem;
        }
      }

      h2 {
        display: inline-block;
        font-size: 3.8rem;
        color: white;
        font-family: ${fonts.SofiaProMedium};
        margin-left: 4rem;
      }
    }

    p {
      font-size: 2rem;
      line-height: 2.8rem;
      color: white;
      font-family: ${fonts.SofiaProMedium};
    }
  }
`;

export const AboutSection = styled.section`
  padding: 0 80px 12rem 80px;

  @media (max-width: 600px) {
    padding: 0 32px 12rem 32px;
  }

  @media (max-width: 425px) {
    padding: 0 16px 12rem 16px;
  }

  h2 {
    font-size: 1.6rem;
    line-height: 2.9rem;
    color: ${landingPalette.orange};
    font-family: ${fonts.SofiaProMedium};
    margin-bottom: 2rem;
    text-align: center;
  }

  h3 {
    font-size: 3.8rem;
    line-height: 4.8rem;
    color: ${landingPalette.blue};
    font-family: ${fonts.SofiaProMedium};
    text-align: center;
    margin-bottom: 26rem;

    @media (max-width: 920px) {
      margin-bottom: 10rem;
    }
  }

  .climeworks-section {
    display: flex;
    justify-content: space-between;
    grid-gap: 7rem;
    margin-bottom: 20rem;
    width: 100%;

    @media (max-width: 920px) {
      flex-direction: column;
      align-items: center;
    }

    figure {
      max-width: 61.1rem;
      width: 100%;

      img {
        width: 100%;
        object-fit: scale-down;
      }
    }

    article {
      max-width: 55rem;
      width: 100%;

      h5 {
        font-size: 3.8rem;
        line-height: 4.8rem;
        color: ${landingPalette.blue};
        font-family: ${fonts.SofiaProSemiBold};
        margin-bottom: 3rem;
      }

      p {
        font-size: 1.6rem;
        line-height: 2.8rem;
        color: ${landingPalette.greyChevron};
        font-family: ${fonts.SofiaProSemiBold};
        margin-bottom: 4rem;
      }

      a {
        font-size: 1.8rem;
        line-height: 3.4rem;
        color: ${landingPalette.green};
        font-family: ${fonts.SofiaProSemiBold};
        text-decoration: none;
      }
    }
  }

  .cooleffect-section {
    display: flex;
    justify-content: space-between;
    grid-gap: 7rem;
    width: 100%;

    @media (max-width: 920px) {
      flex-direction: column-reverse;
      align-items: center;
    }

    figure {
      max-width: 58rem;
      width: 100%;

      img {
        width: 100%;
        object-fit: scale-down;
      }
    }

    article {
      max-width: 48rem;
      width: 100%;

      h5 {
        font-size: 3.8rem;
        line-height: 4.8rem;
        color: ${landingPalette.blue};
        font-family: ${fonts.SofiaProSemiBold};
        margin-bottom: 3rem;
      }

      p {
        font-size: 1.6rem;
        line-height: 2.8rem;
        color: ${landingPalette.greyChevron};
        font-family: ${fonts.SofiaProSemiBold};
        margin-bottom: 4rem;
      }

      a {
        font-size: 1.8rem;
        line-height: 3.4rem;
        color: ${landingPalette.green};
        font-family: ${fonts.SofiaProSemiBold};
        text-decoration: none;
      }
    }

    ul {
      padding: 0;
      margin-left: 2rem;
      margin-bottom: 4rem;
      li {
        font-size: 1.8rem;
        line-height: 2.8rem;
        color: ${landingPalette.blue};
        font-family: ${fonts.SofiaProMedium};
      }
    }
  }
`;

export const ContactSection = styled.section`
  padding: 10rem 80px 8.5rem 80px;
  background-color: ${landingPalette.bgBlue};

  @media (max-width: 600px) {
    padding: 10rem 32px 8.5rem 32px;
  }

  @media (max-width: 425px) {
    padding: 10rem 16px 8.5rem 16px;
  }

  .contact-section {
    justify-content: space-between;
    grid-gap: 6rem;
    width: 100%;

    @media (max-width: 920px) {
      flex-direction: column;
      align-items: center;
    }
  }

  figure {
    max-width: 205px;
    width: 100%;

    img {
      width: 100%;
      object-fit: scale-down;
    }
  }

  article {
    max-width: 51.1rem;
    width: 100%;

    h5 {
      font-size: 3.8rem;
      line-height: 4.8rem;
      color: ${landingPalette.blue};
      font-family: ${fonts.SofiaProSemiBold};
      margin-bottom: 3.2rem;
    }

    p {
      font-size: 2rem;
      line-height: 2.8rem;
      color: ${landingPalette.blue};
      font-family: ${fonts.sofiaProRegular};
      margin-bottom: 3.2rem;

      a {
        color: ${landingPalette.orange};
        text-decoration: none;
      }
    }

    .contact-button {
      border: none;
      outline: none;
      background-color: ${landingPalette.orange};
      border-radius: 21px;
      height: 44px;
      padding: 0 30px;
      font-family: ${fonts.SofiaProMedium};
      font-size: 1.6rem;
      color: white;
      line-height: 2rem;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
    }
  }
`;
